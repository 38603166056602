<template>
  <v-card flat>
    <loading-bar v-model="loading"></loading-bar>
    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="12" md="6">
            Volleyball Life processes tournament registration payments through Stripe Cloud Payments Platform.
            The payments are processed by our platform, on your behalf, as direct charges to your Stripe account.
            The full charge amount - less the <a href="https://stripe.com/us/pricing" target="_blank" class="color3--text">Stripe fees</a>
            and our transaction fee - is deposited directly into your Stripe account.
        </v-col>
        <v-col cols="12" md="6">
          <v-container class="fill-height justify align" v-if="stripeAccounts.length == 0">
            <stripe-connect :organization-id="organization.id"></stripe-connect>
          </v-container>
          <v-list v-else dense two-line subheader>
            <v-subheader>Connected Accounts</v-subheader>
            <v-list-item v-for="(account, i) in stripeAccounts" :key="i">
              <v-list-item-avatar>
                <v-icon class="blue white--text">fab fa-cc-stripe</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ account.displayName }}</v-list-item-title>
                <v-list-item-subtitle>{{ account.businessName }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import StripeConnect from '@/components/Organization/StripeConnect.vue'

export default {
  props: ['username'],
  data () {
    return {
      loading: false,
      stripeAccounts: []
    }
  },
  computed: {
    ...mapGetters(['getPageInfo', 'organization'])
  },
  methods: {
    load () {
      this.loading = true

      this.$VBL.organization.stripe(this.username)
        .then(response => {
          this.stripeAccounts = response.data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    username: 'load',
    'organization.connected': 'load'
  },
  components: {
    StripeConnect
  },
  mounted () {
    this.load()
  }
}
</script>

<style>

</style>
