<template>
  <v-card flat>
    <loading-bar :value="loading"></loading-bar>
    <v-container fluid class="pa-0">
      <v-row dense>
        <v-col cols="12" sm="6">
          <v-text-field
            :label="`${isPs ? 'Point System' : 'Organization'} Name*`"
            v-model="myOrganization.name"
            :hint="`Enter the name of your ${isPs ? 'point system' : 'organization'}`"
            persistent-hint
            :disabled="!edit || saving"
            @change="$v.myOrganization.name.$touch()"
            :error-messages="nameErrors"
            color="color3"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Page User Name*"
            v-model="myOrganization.username"
            :disabled="true"
            color="color3"
          ></v-text-field>
        </v-col>
      </v-row>
      <template v-if="!isPs">
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              name="Email"
              label="Public Email Address*"
              id="email"
              v-model="myOrganization.email"
              @change="$v.myOrganization.email.$touch()"
              :error-messages="emailErrors"
              :disabled="!edit || saving"
              color="color3"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              name="Phone"
              label="Public Phone Number*"
              id="phone"
              v-model="myOrganization.phone"
              v-mask="'(###) ###-####'"
              @change="$v.myOrganization.phone.$touch()"
              :error-messages="phoneErrors"
              :disabled="!edit || saving"
              color="color3"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              label="Welcome Text"
              v-model="myOrganization.welcomeText"
              :disabled="!edit || saving"
              :persistent-hint="edit"
              hint="The text will be displayed on the home page"
              color="color3"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="myOrganization.description"
              label="Description"
              hint="Tell us about your organization. What type of events do you run? Juniors? AAU? AVP? Adults? Clinics? etc."
              :persistent-hint="edit"
              :disabled="!edit || saving"
              color="color3"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Website"
              v-model="myOrganization.websiteUrl"
              hint="Enter the full url to your website"
              :persistent-hint="edit"
              :disabled="!edit || saving"
              color="color3"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Facebook"
              v-model="myOrganization.facebook"
              hint="Enter your facebook page username"
              :persistent-hint="edit"
              :disabled="!edit || saving"
              color="color3"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Instagram"
              v-model="myOrganization.instagram"
              hint="Enter your Instgram handle or username"
              :persistent-hint="edit"
              :disabled="!edit || saving"
              color="color3"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Twitter"
              v-model="myOrganization.twitter"
              hint="Enter your Twitter handle or username"
              :persistent-hint="edit"
              :disabled="!edit || saving"
              color="color3"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Snapchat"
              v-model="myOrganization.snapchat"
              hint="Enter your Snapchat handle or username"
              :persistent-hint="edit"
              :disabled="!edit || saving"
              color="color3"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <!-- EDIT BUTTONS -->
      <v-row dense justify="end">
        <v-fab-transition>
          <v-btn
            color="color3 color3Text--text"
            key="save"
            v-if="edit && dirty && !$v.$invalid"
            fab
            small
            class="my-0 mr-1"
            @click.stop="save"
            :loading="saving"
          >
            <v-icon small>fas fa-save</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            :color="activeFab.color"
            :key="activeFab.icon"
            v-model="fab"
            fab
            small
            class="my-0"
            @click.stop="edit ? cancel() : editMe()"
          >
            <v-icon small>{{activeFab.icon}}</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import ValidUSPhone from '@/helpers/ValidUSPhone'

export default {
  directives: { mask },
  props: ['username'],
  mixins: [validationMixin],
  validations () {
    return {
      myOrganization: {
        name: { required },
        username: { required },
        email: { required, email },
        phone: { required, ValidUSPhone }
      }
    }
  },
  data () {
    return {
      edit: false,
      saving: false,
      organization: null,
      editableOrganization: null,
      fab: null,
      loading: false
    }
  },
  computed: {
    myOrganization () {
      return this.edit ? this.editableOrganization : this.organization || {}
    },
    isPs () {
      return this.myOrganization.pointsOnly
    },
    nameErrors () {
      const errors = []
      if (!this.$v.myOrganization.name.$dirty) return errors
      !this.$v.myOrganization.name.required && errors.push('A name is required')
      return errors
    },
    usernameErrors () {
      const errors = []
      if (!this.$v.myOrganization.username.$dirty) return errors
      !this.$v.myOrganization.username.required && errors.push('A username is required')
      this.available === false && errors.push(`${this.organization.username}.volleyballlife.com is unavailable`)
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.myOrganization.email.$dirty) return errors
      !this.$v.myOrganization.email.required && errors.push('An email is required')
      !this.$v.myOrganization.email.email && errors.push('A valid email is required')
      return errors
    },
    phoneErrors () {
      const errors = []
      if (!this.$v.myOrganization.phone.$dirty) return errors
      !this.$v.myOrganization.phone.required && errors.push('A phone is required')
      !this.$v.myOrganization.phone.ValidUSPhone && errors.push('A valid phone is required')
      return errors
    },
    dirty () {
      return this.editableOrganization && JSON.stringify(this.organization) !== JSON.stringify(this.editableOrganization) && this.editableOrganization.name
    },
    activeFab () {
      return this.edit ? { color: 'red white--text', icon: 'fas fa-times' } : { color: 'color3Text color3--text', icon: 'fas fa-pencil-alt' }
    }
  },
  methods: {
    editMe () {
      this.editableOrganization = JSON.parse(JSON.stringify(this.organization))
      this.edit = true
    },
    cancel () {
      this.saving = false
      this.edit = false
      this.editableOrganization = null
    },
    save () {
      if (!this.dirty || !this.editableOrganization.name) return
      this.saving = true
      this.$VBL.organization.post(this.editableOrganization)
        .then((response) => {
          this.organization = response.data
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.cancel()
        })
    },
    load () {
      var o = this.$store.getters.organization
      if (o && o.username === this.username) {
        this.organization = o
        return
      }
      this.loading = true
      this.$VBL.organization.get(this.username)
        .then((response) => {
          this.organization = response.data
        })
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    username: function (val) {
      this.load()
    }
  },
  mounted () {
    this.load()
  }
}
</script>

<style>

</style>
