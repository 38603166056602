<template>
  <v-container fluid class="grey lighten-4">
    <v-row dense>
      <v-col cols="12">
        <v-expansion-panels hover v-model="panel" multiple>
          <!-- VBL ADMIN SETTINGS -->
          <v-expansion-panel v-if="user.vbl">
            <v-expansion-panel-header color="color1 color1Text--text" class="title">
              VBL Admin
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <admin-settings :username="username" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- PROFILE SETTINGS -->
          <v-expansion-panel>
            <v-expansion-panel-header color="color1 color1Text--text" class="title" :class="{bt: user.vbl}">
              {{isPs ? 'Point System' : page.ratingsOnly ? 'Rating System' : 'Organization'}} Profile
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <organization-profile :username="username"></organization-profile>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- STRIPE -->
          <v-expansion-panel v-if="!page.pointsOnly && !page.ratingsOnly">
            <v-expansion-panel-header color="color1 color1Text--text" class="title bt">
              Payment Account
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <payment-accounts :username="username"></payment-accounts>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrganizationProfile from '@/components/Organization/Profile.vue'
import PaymentAccounts from '@/components/Organization/PaymentAccounts.vue'
import { mapGetters } from 'vuex'
const AdminSettings = () => import('@/components/Organization/Admin.vue')
// import TournamentDefaults from './Components/TournamentDefaults.vue'

export default {
  props: ['username'],
  data () {
    return {
      panel: [0]
    }
  },
  computed: {
    ...mapGetters(['user', 'getPageInfo']),
    a () {
      return window
    },
    page () {
      return this.getPageInfo(this.username)
    },
    isPs () {
      return this.page && this.page.pointsOnly
    }
  },
  methods: {
    setOpen () {
      this.user && this.user.vbl && this.panel.push(1)
    }
  },
  components: {
    OrganizationProfile,
    AdminSettings,
    PaymentAccounts
  },
  watch: {
    username: function (val) {
      this.setOpen()
    }
  },
  mounted () {
    if (this.$route.query.stripe) {
      window.close()
    } else {
      this.setOpen()
    }
  }
}
</script>

<style scoped>
.bt {
  border-top: 1px solid white !important
}
>>> .v-expansion-panel__header {
  margin-bottom: 2px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}
</style>
